<template>
  <div class="md-manage-shou-cang-ji-lu">

    <div class="header">
      <h3>收藏课程</h3> <el-input placeholder="请输入关键词..." class="search-input" v-model="params.videoTitle"
        @clear="getVideoListByCategoryId(1)">
        <i slot="suffix" class="el-icon-search" @click="getUserCollectList()"> </i>
      </el-input>
    </div>
    <ul class="topic-list" v-if="collectList.length">
      <!-- <el-table
      :data="collectList"
      style="width: 100%">
      <el-table-column
        prop="date"
        label="课程信息"
        width="180">
      </el-table-column>
      <el-table-column
        prop="name"
        label="讲师"
        width="180">
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作">
      </el-table-column>
    </el-table> -->

      <li class="topic-item" v-for="item in collectList" :key="item.rid" @click="handleItem(item)">
        <div class="t-img">
          <div class="tag">视频</div>
          <img :src="`/video-dev${item.cover}`" alt="">
        </div>
        <div class="t-right">
          <div class="t-tit-box">
            <p class="t-tit">{{ item.videoTitle }}</p>
            <p class="t-type">
              <span>上传时间：{{ item.createTime }}</span>
            </p>
          </div>
        </div>
      </li>
    </ul>
    <p v-else class="no-data">暂无数据</p>
    <!-- 分页 -->
    <div class="pagination" v-if="collectList.length">
      <el-pagination @current-change="handleCurrentChange" :current-page="+params.pageNum" :page-size="params.pageSize"
        layout="total, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getUserCollectList } from '@/axios/user'
export default {
  name: 'MdManageShouCangJiLuComponent',
  data() {
    return {
      collectList: [],
      params: {
        pageNum: 1,
        pageSize:5,
        videoTitle:'',
      },
      total: 0
    }
  },
  created() {
    this.getUserCollectList()
  },
  methods: {
    async getUserCollectList() {
      const res = await getUserCollectList(this.params)
      if (res.code === 200) {
        this.collectList = res.rows
        this.total = res.total
      }
    },
    handleItem(item) {
      this.$router.push({
        path: '/courseDetail',
        query: {
          rid: item.videoId
        }
      })
    },
    handleCurrentChange(val) {
      this.params.pageNum = val
      this.getUserCollectList()
    }
  }
}
</script>
<style lang="less" scoped>
.md-manage-shou-cang-ji-lu {
  width: 100%;
  background: #fff;
  padding: 20px 30px;
}

h3 {
  font-size: 24px;
  // margin-bottom: 37px;
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 37px;
}

.topic-list {
  width: 100%;

  .topic-item {
    padding: 20px 0;
    border-bottom: 1px solid rgb(230, 230, 230);
    display: flex;
    align-items: center;
    cursor: pointer;

    &:first-child {
      padding-top: 0;
    }

    .t-img {
      width: 126px;
      height: 84px;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 20px;
      flex-shrink: 0;
      position: relative;

      .tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: 3px 5px;
        font-size: 12px;
        border-radius: 0 4px 0 4px;
        background: rgba(0, 0, 0, .2);
        color: rgba(255, 255, 255, .8);
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .t-right {
      flex: 1;
      height: 100px;
      display: flex;
      align-items: center;

      .t-tit-box {
        flex: 1;

        .t-tit {
          font-size: 16px;
          margin-bottom: 20px;
        }

        .t-type {
          span {
            color: #888;
            font-size: 12px;
            margin-right: 30px;
          }
        }
      }

      .q-btn {
        margin-left: 10px;
        flex-shrink: 0;
      }
    }
  }
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.no-data {
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
}

.search-input{
  width: 240px;

  position: relative;
}

::v-deep{
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #0FB09B;
  }

  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    border: 1px solid  #CCCCCC;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
   
  }
  .el-pagination button, .el-pagination span:not([class*=suffix]){
   height: 36px;
   line-height: 36px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: #0FB09B;
  }
}

/deep/ .search-input {
  .el-input__inner {
    border: none;
    width: 240px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 18px;
    border: 1px solid rgba(15, 176, 155, 1);
    height: 36px;
  }

  .el-input__suffix {
    i {
      margin-top: 10px;
      margin-right: 3px;
      font-size: 16px;
      font-weight: bold;
      color: rgba(15, 176, 155, 1);
      cursor: pointer;

      &:hover {
        color: @md-primary-color;
      }
    }
  }
}

/deep/ .el-pager li.active {
  color: @md-primary-color;
  ;
}

/deep/ .el-pager li:hover {
  color: @md-primary-color;
  ;
}

/deep/ .el-input__inner:focus {
  border-color: @md-primary-color !important;
}

/deep/ .el-pagination button:hover {
  color: @md-primary-color;
}

::v-deep{
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #0FB09B;
  }

  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    border: 1px solid  #CCCCCC;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
   
  }
  .el-pagination button, .el-pagination span:not([class*=suffix]){
   height: 36px;
   line-height: 36px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: #0FB09B;
  }
}
</style>